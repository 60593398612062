<template>
    <v-navigation-drawer
        v-model="show"
        fixed
        hide-overlay
        app
        class="gst-menu">
        <div class="gst-menu__header d-flex mx-4 my-3">
            <v-btn icon class="float-md-none float-lg-none" @click="show = false">
                <BaseIcon class="gst-icon-chevron-left" symbol-id="icons--dropdown_arrow" />
            </v-btn>
            <h4 class="d-flex mx-2 my-2">
                {{ $t('menuTitle') }}
            </h4>
            <div class="gst-menu__invisible" style="height: 36px; width: 36px;"></div>
        </div>
        <v-divider />
        <div class="gst-menu__links-wrapper">
            <div class="my-8 ml-6" tabindex="0" @click="onClickDo" @keypress.esc="onClickDo">
                <router-link class="gst-menu__search-link-wrapper d-flex" :to="{ name: 'home', hash: '#search-products' }">
                    <BaseIcon symbol-id="icons--search" class="mr-1" />
                    <h5>{{ $t('linkSearch') }}</h5>
                </router-link>
            </div>
            <v-divider class="mx-4" />
            <div class="d-flex my-8 ml-6" tabindex="0" @click="show = false" @keypress.esc="show = false">
                <router-link :to="{ name: 'home', hash: '#latest-deals' }">
                    <h5>{{ $t('linkLatestDeals') }}</h5>
                </router-link>
            </div>
            <v-divider class="mx-4" />
            <div class="d-flex my-8 ml-6" tabindex="0" @click="show = false" @keypress.esc="show = false">
                <router-link :to="{ name: 'home', hash: '#faq' }">
                    <h5>{{ $t('linkFaq') }}</h5>
                </router-link>
            </div>
        </div>
        <div class="gst-menu__language-selector d-flex justify-center pb-8 px-6">
            <LanguageSelectField is-secondary-type show-on-top :has-short-form="false" />
        </div>
        <div class="gst-menu__footer d-flex">
            <div class="d-flex mt-10" tabindex="0" @click="show = false" @keypress.esc="show = false">
                <router-link target="_blank" :to="{ name: 'policy' }">
                    <h5>{{ $t('linkTermsOfUse') }}</h5>
                </router-link>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
    import BaseIcon from '@core/shared/components/misc/BaseIcon.vue';
    import LanguageSelectField from '@tenant/app/components/inputs/LanguageSelectField.vue';

    export default {
        name: 'MobileMenu',
        components: {
            BaseIcon,
            LanguageSelectField
        },
        i18nOptions:{
            namespaces: 'main',
            keyPrefix: 'views.home.theHeader'
        },
        data() {
            return {
                show: true
            };
        },
        watch: {
            'show': function ( value ) {
                if ( !value ) {
                    this.$emit( 'close' );
                }
            }
        }
    };
</script>
